/* Override old Styles for LegacyLoginView */
#username,
#password
{
	height: 34px;
	padding: 5px;
	font-size: 12px;
	background-color: rgba(255,255,255,0.9);
	border: 1px solid #ccc;
	border-radius: 2px;
	width: 260px !important;
	margin: 0 auto 15px;
	box-sizing: border-box;
	display: block;
	box-shadow: none;
	transition-property: box-shadow, border;
	transition-duration: 0.3s, 0.3s;
}
/* keine Ahnung warum, aber wenn ich die Styles mit Komma konkateniere, funktioniert es nicht */
#username::-webkit-input-placeholder { color: #999; opacity: 0.8; }
#username::-moz-placeholder { color: #999; opacity: 0.8; }
#username::-ms-input-placeholder { color: #999; opacity: 0.8; }
#username:-ms-input-placeholder { color: #999; opacity: 0.8; }
#password::-webkit-input-placeholder { color: #999; opacity: 0.8; }
#password::-moz-placeholder { color: #999; opacity: 0.8; }
#password::-ms-input-placeholder { color: #999; opacity: 0.8; }
#password:-ms-input-placeholder { color: #999; opacity: 0.8; }

#username:hover,
#password:hover
{
	border: 1px solid white;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

#submitButton {
	margin-left: 10px;
	font-family: Raleway, Verdana, sans-serif;
	height: 34px;
	width: 80px !important;
	padding: 5px;
	font-size: 12px;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
	border-radius: 2px;
	background-color: #74B742;
	color: #f2f2f2;
	transition: box-shadow 0.3s;
	margin-top: 0 !important;
	background-image: none;
	text-shadow: none;
	font-weight: normal;
}
#submitButton:hover {
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}
#passwordLink {
	font-weight: normal;
	box-shadow: none;
	margin-top: 12px;
	color: #555;
	background: none;
	border: none;
	text-align: left;
	margin-left: 0;
	font-size: 11px;
}

/* new LegacyLoginView */
.loginBox {
	margin: 180px auto 0; /* fixe 180, weil es hier kein Script gibt */
	width: 280px;
}
.loginBox h1 {
	text-align: center;
	line-height: 42px;
	background: #000;
	color: #FFF;
	margin: 15px 10px;
	font-family: Times, "Times New Roman", serif;
	font-variant: small-caps;
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 4px;
}

/* new base.twig */
/* you have to write div as first, otherwise it will be overwritten, by new-look-white.css */
div#header.bar {
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    top: 0;
	width: 100%;
    min-width: 950px;
    position: fixed;
    color: #FFF;
    z-index: 1000;
    background: #000;
    background: linear-gradient(to bottom, #333 25%, #000 100%);
	border-bottom: none;
}
div#header.bar a {
	color: white;
}
div#navigation-button-wrapper {
	display: none;
}
div#content {
	margin-top: 20px;
}
div#footer {
	width: 100%;
	min-width: 950px;
	position: fixed;
	color: #FFF;
	z-index: 1000;
	background: linear-gradient(to bottom, #333 40%, #000 100%);
}
p#footerLinks a {
	font-weight: normal;
}